.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkContainer img {
  width: 38px;
  height: 38px;
  margin-right: 1em;
  vertical-align: middle !important;
}

.activeLink {
  color: #fff !important;
  border-color: hsla(0, 0%, 100%, 0.2) !important;
  background: rgba(0, 0, 0, 0.7) !important;
}
